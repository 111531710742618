import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import articleData from '../util/guides.json';
import ReactMarkdown from 'react-markdown';

function ArticleList() {
  const [searchTerm, setSearchTerm] = useState('');
  const [tags, setTags] = useState([]);
  const [activeTags, setActiveTags] = useState([]);

  // Extract unique tags from the articles
  const updateTags = () => {
    const allTags = Object.values(articleData).reduce((acc, article) => {
      if (article.hide === true) {
        return acc;
      }
      return [...acc, ...article.tags];
    }, []);
  
    setTags([...new Set(allTags)]);
  };
  

  useEffect(() => {
    updateTags();
  }, []);

  const toggleTag = (tag) => {
    if (activeTags.includes(tag)) {
      setActiveTags(activeTags.filter((t) => t !== tag));
    } else {
      setActiveTags([...activeTags, tag]);
    }
  };

  const highlightSearchTerm = (title) => {
    //only do it if title is longer than 3 characters
    if (searchTerm.length < 3) return { __html: title };
    const searchTermRegex = new RegExp(`(${searchTerm})`, 'gi');
    const highlightedTitle = title.replace(
      searchTermRegex,
      '<span class="🔥 text-color1-contrast bg-color1-main">$1</span>'
    );
    return { __html: highlightedTitle };
  };

  const filterArticles = () => {
    return Object.values(articleData).filter((article) => {
      const searchFilter = searchTerm === '' || article.title.toLowerCase().includes(searchTerm.toLowerCase());
      const tagFilter = activeTags.length === 0 || activeTags.every((tag) => article.tags.includes(tag));
      const hideFilter = article.hide !== true;

      return searchFilter && tagFilter && hideFilter;
    });
  };

  const hyphenateTitle = (title) => {
    return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  };

  return (
    <div className="p-4 container mx-auto">
      <h1 className="text-center my-10 🔥 h1">Guides, info, &amp; how to</h1>
      <div className='p-4 bg-white rounded-lg'>
      <input
        type="text"
        placeholder="Search articles..."
        className="🔥 🧩:text 📐:large 🎨:primary 👀:outline ✋ w-full block mb-4"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      
      <div className="pt-4">
        {tags.map((tag, index) => (
          <span
            key={index}
            onClick={() => toggleTag(tag)}
            className={`🔥 🧩:badge 📐:medium 👀:block inline-block cursor-pointer mr-2 mb-2 text-semibold ${
              activeTags.includes(tag) ? '🎨:color1' : '🎨:neutral-dark'
            }`}
          >
            {tag}
          </span>
        ))}
      </div>
      </div>
      <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-8 pt-8'>
      {filterArticles().map((article, index) => {
  // Calculate word count and reading time
  const wordCount = article.content.split(' ').length;
  const readingTime = Math.ceil(wordCount / 200);

  return (
    <div key={index} className="mb-4 bg-white p-4 rounded-lg shadow-md">
      <Link to={`/articles/${hyphenateTitle(article.title)}`} className="🔥 text-xl font-semibold hover:text-color1-main">
        <span dangerouslySetInnerHTML={highlightSearchTerm(article.title)} />
      </Link>
      <p className="text-sm text-gray-500">&lt; {readingTime} min read</p>
      <div className='pt-4'>
        {article.tags.map((tag, idx) => (
          <span key={idx} className={`🔥 🧩:badge 📐:small ${
              activeTags.includes(tag) ? '🎨:color1' : '🎨:neutral-light'
            } 👀:form-highlight inline-block  mr-2 mb-2`}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
})}

      </div>
    </div>
  );
}

export function Article({ hyphenatedTitle }) {
    const findArticleByText = () => {
      const article = Object.values(articleData).find((article) => {
        const hyphenated = article.title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        return hyphenated === hyphenatedTitle;
      });
      return article;
    };
  
    const article = findArticleByText();
 
    if (!article) {
      return <div>Article not found.</div>;
    }
  
    return (
      <div className="p-4 container mx-auto">

        <div className="article-display py-8">
            <Link to="/guides" className='🔥 🧩:btn 📐:small 🎨:neutral-dark 👀:block ✋'><i class="fa fa-arrow-left" aria-hidden="true"></i> Guides</Link>
        </div>
        <div className="article-display bg-white shadow-lg rounded-xl p-8">
        <ReactMarkdown>{article.content}</ReactMarkdown>
        </div>
        <div className="article-display py-8">
        <Link to="/guides" className='🔥 🧩:btn 📐:small 🎨:neutral-dark 👀:block ✋'><i class="fa fa-arrow-left" aria-hidden="true"></i> Guides</Link>
        </div>
      </div>
    );
  }

export default ArticleList;