import React from "react";
import PropTypes from "prop-types";

const Input = ({ size = "medium", color = "color1", variant = "form-default", className = "", ...rest }) => {
  const inputClass = `🔥 🧩:text 📐:${size} 🎨:${color} 👀:${variant} ${className} p-2`;

  return <input className={inputClass} {...rest} />;
};

Input.propTypes = {
  size: PropTypes.oneOf(["large", "medium", "small"]),
  color: PropTypes.oneOf(["neutral-light", "neutral-dark", "success", "warning", "danger", "color1", "color2"]),
  variant: PropTypes.oneOf(["form-default", "outline", "form-highlight"]),
  className: PropTypes.string,
};

export default Input;
