const API_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? "/.netlify/functions/jsonbin-api"
    : "http://localhost:8888/.netlify/functions/jsonbin-api";

export async function createBin(data, name) {
  const response = await fetch(API_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Bin-Name": name,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Error creating bin: ${response.statusText}`);
  }

  const result = await response.json();
  return result;
}

export async function readBin(binId) {
  const response = await fetch(`${API_ENDPOINT}?binId=${binId}`, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(`Error fetching bin: ${response.statusText}`);
  }

  const result = await response.json();
  return result.record;
}

export async function updateBin(binId, data) {
  const response = await fetch(`${API_ENDPOINT}?binId=${binId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Error updating bin: ${response.statusText}`);
  }

  const result = await response.json();
  return result;
}
