import { useState } from "react";
import WizardForm from "./WizardForm";

function contrastIsHighEnough(hex, threshold = 128) {
  const rgb = parseInt(hex, 16);
  const red = (rgb >> 16) & 0xff;
  const green = (rgb >> 8) & 0xff;
  const blue = (rgb >> 0) & 0xff;
  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

  return brightness < threshold;
}

function getRandomHexColorWithHighContrastToWhite() {
  let color;
  do {
    color = Math.floor(Math.random() * 0xffffff).toString(16);
  } while (!contrastIsHighEnough(color) || color.length < 6);

  return '#' + color;
}


function getRandomComplimentaryColors() {
  
  const colors = [getRandomHexColorWithHighContrastToWhite(), getRandomHexColorWithHighContrastToWhite(), getRandomHexColorWithHighContrastToWhite()];

  return colors;
}


const Create = () => {
  const colors = getRandomComplimentaryColors();
  const [formData, setFormData] = useState({
    "brand": {
      "name": "",
      "displayName": "Company name",
      "prefix": "f"
    },
    "color": {
        "theme": {
          "primary": {
            "main": colors[0]
          },
          "secondary": {
            "main": colors[1]
          },
          "tertiary": {
            "main": colors[2]
          }
        }
      },
      "styling": {
        "borderRadii": {
            "buttons": "4",
            "inputs": "4",
        }
    },
      "button": {
      "metaData": {
        "colors": [
          "primary",
          "secondary",
          "tertiary",
          "danger",
          "success",
          "warning",
          "disabled",
          "neutral-dark",
          "neutral-light"
        ],
        "styles": [
          "block",
          "outline",
          "ghost"
        ],
        "sizes": [
          "small",
          "medium",
          "large"
        ]
      }
    },
    "badge": {
      "metaData": {
        "colors": [
          "primary",
          "secondary",
          "tertiary",
          "danger",
          "success",
          "warning",
          "disabled",
          "neutral-dark",
          "neutral-light"
        ],
        "styles": [
          "block",
          "outline"
        ],
        "sizes": [
          "small",
          "medium",
          "large"
        ]
      }
    },
    "accordion": {
      "metaData": {
        "colors": [
          "primary",
          "secondary",
          "tertiary",
          "danger",
          "success",
          "warning",
          "neutral-dark",
          "neutral-light"
        ],
        "styles": [
          "block",
          "ghost"
        ],
        "sizes": [
          "small",
          "medium",
          "large"
        ]
      }
    },
    "select": {
      "metaData": {
        "colors": [
          "primary",
          "secondary",
          "tertiary",
          "danger",
          "success",
          "warning",
          "neutral-dark",
          "neutral-light"
        ],
        "styles": [
          "form-default",
          "form-highlight",
          "outline"
        ],
        "sizes": [
          "small",
          "medium",
          "large"
        ]
      }
    },
    "text-input": {
      "metaData": {
        "colors": [
          "primary",
          "secondary",
          "tertiary",
          "danger",
          "success",
          "warning",
          "neutral-dark",
          "neutral-light"
        ],
        "styles": [
          "form-default",
          "form-highlight",
          "outline"
        ],
        "sizes": [
          "small",
          "medium",
          "large"
        ]
      }
    },
    "type": {
      "fonts": [
        {
          "name": "default",
          "displayName": "Default font",
          "fontName": "Open Sans",
          "url": "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
        },
        {
          "name": "display",
          "displayName": "Display font",
          "fontName": "Poppins",
          "url": "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap"
        }
      ],
      "typefaces": {
        "body": "Roboto",
        "display": "Poppins"
      },
      "wizard": {
        "body": "Roboto",
        "display": "Poppins"
      }
    },
    "checkbox": {
      "metaData": {
        "colors": [
          "primary",
          "secondary",
          "tertiary",
          "disabled",
          "danger",
          "warning",
          "success",
          "neutral-dark",
          "neutral-light"
        ],
        "styles": [
          "block",
          "outline",
          "form-default",
          "form-highlight"
        ],
        "sizes": [
          "small",
          "medium",
          "large"
        ]
      }
    }
  });

  const handleUpdateFormData = (updatedFormData) => {
    setFormData(updatedFormData);
  };

  const pages = [
    {
      title: "About your design system",
      questions: [
        {
          id: "brand.displayName",
          text: "What shall we call it?",
          type: "text"
        },
      ],
    },
    {
      title: "Your brand colours",
      questions: [
        {
          id: "color.theme.primary.main",
          text: "Primary",
          type: "color"
        },
        {
          id: "color.theme.secondary.main",
          text: "Secondary",
          type: "color"
        },
        {
          id: "color.theme.tertiary.main",
          text: "Tertiary",
          type: "color"
        },
      ],
    },
    {
      title: "Fonts",
      questions: [
        {
          id: "type.wizard.display",
          text: "Display font",
          helper: "This is the font used for headings, buttons, and titles",
          type: "font",
        },
        {
          id: "type.wizard.body",
          text: "Your body font",
          helper: "This is the font used for most of your text",
          type: "font",
          previewType: "body",
        },
      ],
    },
    {
      title: "Border radius (corner rounding)",
      questions: [
        {
          id: "styling.borderRadii.buttons",
          text: "Buttons",
          type: "radio",
          options: [
            { value: "2", label: "Small" },
            { value: "4", label: "Medium" },
            { value: "8", label: "Large" },
            { value: "9999", label: "Pill-style" },
          ],
        },
        {
          id: "styling.borderRadii.inputs",
          text: "Other form inputs",
          type: "radio",
          options: [
            { value: "2", label: "Small" },
            { value: "4", label: "Medium" },
            { value: "8", label: "Large" },
          ],
        },
      ],
    },
  ];
  
  

  return (
    <><div className="🔥 bg-neutral-800 flex justify-center items-center min-h-full lg:h-full">
          <WizardForm
              pages={pages}
              initialFormData={formData}
              onUpdateFormData={handleUpdateFormData} />
              
      </div>
      <div className="🔥 bg-neutral-800 flex justify-center items-center">
      
              {/* <pre className="text-white mt-8">{JSON.stringify(formData, null, 2)}</pre> */}
          </div>
          </>
  );
};

export default Create;
