const messages = [
    "Smelting...",
    "Almost there...",
    "Firing up the foundry...",
    "Using oven gloves...",
    "Getting warmer...",
  ];
  
  let messageIndex = 0;
  let messageTimeout;
  
  function showMessage() {
    const messageContainer = document.getElementById('overlay-message');
    messageContainer.textContent = messages[messageIndex];
    messageIndex = (messageIndex + 1) % messages.length;
    messageTimeout = setTimeout(showMessage, 1250);
  }
  
  export function showOverlaySpinner() {
    const overlay = document.createElement('div');
    overlay.id = 'overlay';
    overlay.style.cssText = `
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.75);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `;
    
    const spinner = document.createElement('div');
    spinner.className = 'spinner-2 🔥 🎨:neutral-light';
    overlay.appendChild(spinner);
  
    const messageContainer = document.createElement('p');
    messageContainer.id = 'overlay-message';
    messageContainer.className = '🔥 text-neutral-light-main h1 pt-20';
    overlay.appendChild(messageContainer);
  
    document.body.appendChild(overlay);
  
    showMessage();
  }
  
    export function hideOverlaySpinner() {
    clearTimeout(messageTimeout);
    const overlay = document.getElementById('overlay');
    overlay.parentNode.removeChild(overlay);
    }