import React from "react";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useParams } from "react-router-dom";
import Create from "./components/create.js";
import ArticleList, { Article } from "./components/guides.js";
import AdminDashboard from "./components/brandbin.js";
import './index.css';

const ArticleWrapper = () => {
  const { hyphenatedTitle } = useParams();
  return <Article hyphenatedTitle={hyphenatedTitle} />;
};


function Header() {
  const handleClick = (event) => {
    event.preventDefault();
    if (typeof window.tidioChatApi.open === 'function') {
      window.tidioChatApi.open();
      // prevent default link behavior
      return false;
    } else {
      console.warn('myExternalFunction is not defined');
    }
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 1024) { // Assuming mobile devices have a width <= 1024
      toggleMenu();
    }
  };
  

  const [menuOpen, setMenuOpen] = useState(false);
  const [disableScroll, setDisableScroll] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setDisableScroll(!disableScroll);

    if (disableScroll) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const navLinks = (
    <>
      <li>
        <Link to="/" className="text-white hover:text-gray-300" onClick={handleLinkClick}>
          🔥 About foundry
        </Link>
      </li>
      <li>
        <Link to="/create" className="text-white hover:text-gray-300" onClick={handleLinkClick}>
          ⏳ Get started
        </Link>
      </li>
      <li>
        <a
          href="https://docs.foundry.style/2503/"
          target={`_blank`}
          className="text-white hover:text-gray-300"
          onClick={(e) => {handleLinkClick();}}
        >
          🧩 Demo components
        </a>
      </li>
      <li>
        <Link to="/guides/" className="text-white hover:text-gray-300" onClick={handleLinkClick}>
          🧐 Guides
        </Link>
      </li>
      <li>
        <a
          href="mailto:skippy@foundry.style"
          className="text-white hover:text-gray-300"
          onClick={(e) => {handleLinkClick(); handleClick(e);}}
        >
          💬 Get in touch
        </a>
      </li>
    </>
  );
  
  return (
    <header className="🔥 bg-neutral-800 p-6 flex lg:justify-between justify-center lg:h-20 items-center lg:sticky lg:top-0">
      <h1
        className={`${
          menuOpen ? "hidden" : ""
        } 🔥 text-neutral-dark-contrast font-bold h2 text-center`}
      >
        <Link to="/" className=" flex flex-row items-center"><img src="/logo.png" className="w-12 mr-8" alt="Foundry logo" /></Link>
      </h1>
      <nav>
  <div className="flex items-center justify-between">
    <div className="hidden lg:block">
      <ul className="flex flex-col space-y-2 lg:space-y-0 lg:space-x-8 lg:flex-row">
          {navLinks}
      </ul>
    </div>
    {/* Hamburger icon */}
<button
  className={`lg:hidden text-white focus:outline-none z-10 right-0 top-0 absolute m-4 ${
    menuOpen ? 'hidden' : 'block'
  }`}
  onClick={toggleMenu}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 6h16M4 12h16M4 18h16"
    />
  </svg>
</button>

{/* Close icon */}
<button
  className={`lg:hidden text-white focus:outline-none z-10 right-0 top-0 absolute m-4 ${
    menuOpen ? 'block' : 'hidden'
  }`}
  onClick={toggleMenu}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
</button>


  </div>
  <div
          className={`${
            menuOpen ? "block" : "hidden"
          } space-y-2 lg:hidden`}
        >
          <ul className="flex flex-col absolute 🔥 bg-neutral-800 w-screen left-0 top-0 h-full h2 justify-between text-center py-16 z-0">
            {navLinks}
          </ul>
        </div>
      </nav>
    </header>
  );
}

function Home() {
  return (
    <div>
      <section className="🔥 bg-neutral-800">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
          <h2 className="🔥 text-neutral-dark-contrast mb-10 text-4xl font-extrabold tracking-tight leading-none md:text-4xl lg:text-5xl font-mono">Code &amp; Figma starter kit in 60 seconds</h2>
          <p className="🔥 text-neutral-200 mb-10 text-lg font-normal lg:text-xl sm:px-16 xl:px-48">Foundry provides an extensive UI kit for designers and developers to create custom design systems from common foundations.

          </p>
          <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <Link className="🔥 🧩:btn 📐:large 🎨:color1 👀:block ✋ inline-flex items-center justify-center" to="/create">
              Create a styleguide
              <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </Link>
            <a className="🔥 🧩:btn 📐:large 🎨:neutral-light 👀:block ✋" href="https://docs.foundry.style/0000/" target={`_blank`}>👀 Example kit</a>

          </div>
        </div>
      </section>

      <section className="m-4 md:m-8 pt-8">
        <div className="🔥 h1 text-center w-full">
          Foundry is...
        </div>
        <div className="container mx-auto grid justify-center gap-4 sm:grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col items-center p-4">
            <h3 className="🔥 h2 text-center my-6 font-semibold text-color1-main">Automated</h3>
            <div className="space-y-1 max-w-xl">
              <p>Foundry automates the first step for any design system - turning basic information about your brand into usable components for designers and developers to start using within minutes.</p>
            </div>
          </div>

          <div className="flex flex-col items-center p-4">
            <h3 className="🔥 h2 text-center my-6 font-semibold text-color1-main">Maximalist</h3>
            <div className="max-w-xl">
              <p>It's a numbers game. Foundry creates a 'superset' of components, focusing on all the things you might ever need rather than only things you can predict needing.</p>
              <p className="mt-4">Let foundry create the hundreds of components you might ever need so you can focus on creating patterns to meet user needs.</p>
            </div>
          </div>

          <div className="flex flex-col items-center p-4">
            <h3 className="🔥 h2 text-center my-6 font-semibold text-color1-main">For Code &amp; Design</h3>
            <div className="max-w-xl">
              <p>
                Foundry creates components for both designers and developers to use. Designers can use the components in their design tools, developers can use the components in their code, and foundry will keep everything in-sync.
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center p-4">
            <h3 className="🔥 h2 text-center my-6 font-semibold text-danger-main">In alpha</h3>
            <div className="max-w-xl">
              <p>
                Foundry is in alpha. It's not ready for production use yet, but it's getting there. If you're interested in using it, please get in touch.
              </p>
            </div>
          </div>

        </div>
      </section>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About Us</h2>
      <p>This is the about page.</p>
    </div>
  );
}

function Contact() {
  return (
    <div>
      <section className="🔥 bg-neutral-900">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-lg">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 ">Contact Us</h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-100 sm:text-xl">Send an email with questions about business to business things.</p>
          <form action="#" className="space-y-8 ">
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-300">Your email</label>
              <input type="email" id="user-email" className="🔥 🧩:text 🎨:neutral-light 👀:outline ✋" placeholder="whoareyou@whereareyou.com" required />
            </div>
            <div>
              <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-300">Subject</label>
              <input type="text" id="subject" className="🔥 🧩:text 🎨:neutral-light 👀:outline ✋" placeholder="Let us know how we can help you" required />
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-400">Your message</label>
              <textarea id="message" rows="6" className="🔥 🧩:text 🎨:neutral-light 👀:outline ✋" placeholder="Leave a comment..."></textarea>
            </div>
            <button className="🔥 🧩:btn 📐:large 🎨:color1 👀:block ✋">
              {/* SVG of a folded paper airplane */}
              <i className="fa-solid fa-paper-plane mr-4"></i>
              Send it
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="min-h-screen lg:h-screen flex flex-col">
        <Header />
        <div className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create" element={<Create />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/guides" element={<ArticleList />} />
          <Route path="/articles/:hyphenatedTitle" element={<ArticleWrapper />} />
          <Route path="/brand-data" element={<AdminDashboard />} />
          <Route path="/brand-data/:brandBinId" element={<AdminDashboard />} />
        </Routes>
        </div>
        <footer className="🔥 bg-neutral-900 text-neutral-200">
          <div className="container mx-auto py-8 px-4">
            <div className="flex items-center sm:flex-row justify-center">
              <div className="flex items-center space-x-4">
                &copy; 2023. 🦘 Made in Sydney from Australian and imported ingredients.
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
