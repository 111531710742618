import React from "react";
import PropTypes from "prop-types";

const Button = ({ size = "large", color = "color1", variant = "block", children, ...rest }) => {
  const buttonClass = `🔥 🧩:btn 📐:${size} 🎨:${color} 👀:${variant} ✋`;

  return (
    <button className={buttonClass} {...rest}>
      {children}
    </button>
  );
};

Button.propTypes = {
  size: PropTypes.oneOf(["large", "medium", "small"]),
  color: PropTypes.oneOf(["neutral-light", "neutral-dark", "success", "warning", "danger", "color1", "color2"]),
  variant: PropTypes.oneOf(["solid", "outline", "ghost", "block"]),
  children: PropTypes.node.isRequired,
};

export default Button;
