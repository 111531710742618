import { useState, useEffect } from "react";
import { readBin } from "../util/jsonbin";
import { Link, useParams } from 'react-router-dom';
import FileTabs from "./FileTabs";

const INDEX_BIN_ID = "6432bfa4ace6f33a2207b00b";

function AdminDashboard() {
  const { brandBinId } = useParams();
  const [binList, setBinList] = useState([]);
  const [selectedBin, setSelectedBin] = useState("");
  const [selectedBinId, setSelectedBinId] = useState("");

  useEffect(() => {
    readBinList();
  }, []);

  const readBinList = async () => {
    const binData = await readBin(INDEX_BIN_ID);
    const bins = Object.entries(binData)
      .filter(([key, value]) => value !== null)
      .map(([key, value]) => {
        return {
          id: key,
          binId: value,
          displayName: "",
        };
      });
    setBinList(bins);
  };

  useEffect(() => {
    if (brandBinId) {
      loadBin(brandBinId);
      setSelectedBinId(binList[brandBinId]);

      binList.forEach((bin) => { // Line 36: Changed from Object.entries(binList).filter to binList.forEach
        if (bin.id === brandBinId) {
          setSelectedBinId(bin['binId']);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandBinId]); // Line 43: Added eslint-disable-next-line comment

  const loadBin = async (binId) => {
    setSelectedBin("");
    setSelectedBinId("");
    //const data = await readBin(binId);
    setSelectedBin(binId);
  };

  return (
    <div className="grid grid-cols-12 gap-4 min-h-screen">
      <div className="col-span-3 🔥 bg-neutral-100 pt-px">
        {binList.map((bin) => (
          <Link
            key={bin.id}
            className={`cursor-pointer block p-4 🔥 hover:bg-neutral-800 hover:text-neutral-50 ${selectedBin === bin.id ? 'bg-neutral-800 text-neutral-50' : ''}`}
            to={`/brand-data/${bin.id}`}
          >
            <div className="text-xl font-bold mb-2">{bin.id}</div>
            <div className="text-md">{bin.binId}</div>
            <div className="text-md">{bin.displayName}</div>
          </Link>
        ))}
      </div>
      <div className="col-span-9 p-6">
        {selectedBin && (
          <FileTabs brandID={selectedBin} binID={selectedBinId} />
        )}
      </div>
    </div>
  );
}

export default AdminDashboard;