import { fonts } from "../util/fonts";

export function adaptJson(json) {
    function hyphenateAndLowercase(str) {
        return str.toLowerCase().replace(/\s+/g, '-');
    }

    function getContrast(hexcolor) {
        const rgb = parseInt(hexcolor.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        const luma = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luma > 0.5 ? '#111111' : '#FFFFFF';
    }

    function getSubtleHover(contrast) {
        return contrast === '#FFFFFF' ? 'rgba(0,0,0,0.06)' : 'rgba(255,255,255,0.075)';
    }

    function getSubtleHoverContrast(contrast) {
        return contrast === '#111111' ? 'rgba(0,0,0,0.06)' : 'rgba(255,255,255,0.075)';
    }

    function hexToRgb(hex) {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
      }
      
      function rgbToHex(r, g, b) {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
      }
      
      function blendColors(c0, c1, alpha) {
        const [r1, g1, b1] = hexToRgb(c0);
        const [r2, g2, b2] = hexToRgb(c1);
        const r = Math.round(r1 * (1 - alpha) + r2 * alpha);
        const g = Math.round(g1 * (1 - alpha) + g2 * alpha);
        const b = Math.round(b1 * (1 - alpha) + b2 * alpha);
        return rgbToHex(r, g, b);
      }
      
      function getTintContrast(main, contrast) {
        const overlayOpacity = 0.95;
        return blendColors(main, contrast, overlayOpacity);
      }
      
    function getNeutral(contrast) {
        return contrast === '#FFFFFF' ? '#111111' : '#FFFFFF';
    }

    function getTint(hexcolor) {
        console.log(`Getting tint for ${hexcolor}`);

        const contrastRatio = (color1, color2) => {
            const l1 = (0.299 * parseInt(color1.slice(1, 3), 16) + 0.587 * parseInt(color1.slice(3, 5), 16) + 0.114 * parseInt(color1.slice(5, 7), 16)) / 255;
            const l2 = (0.299 * parseInt(color2.slice(1, 3), 16) + 0.587 * parseInt(color2.slice(3, 5), 16) + 0.114 * parseInt(color2.slice(5, 7), 16)) / 255;
            return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
          };
      
        const adjustLightness = (color, delta) => {
          const hsl = hexToHsl(color);
          console.log(`HSL for ${color}: ${hsl}`);
      
          const hslParts = hsl.slice(4, -1).split(',').map((value, index) => {
            if (index === 0) {
              return parseFloat(value);
            } else {
              return parseFloat(value) / 100;
            }
          });
      
          let [h, s, l] = hslParts;
          l = l + delta; // Adjust lightness calculation
          l = Math.max(0, Math.min(1, l));
          console.log(`Adjusted hex for hsl(${h}, ${s * 100}%, ${l * 100}%): ${hslToHex(`hsl(${h}, ${s * 100}%, ${l * 100}%)`)}`)
          return hslToHex(`hsl(${h}, ${s * 100}%, ${l * 100}%)`);
        };
      
        const isLightColor = (color) => {
          return contrastRatio(color, "#FFFFFF") < contrastRatio(color, "#000000");
        };
      
        let l = isLightColor(hexcolor) ? -0.1 : 0.1;
        console.log(`Is light color: ${isLightColor(hexcolor)}`)
        console.log(`Lightness: ${l}`)
      
        let tint = adjustLightness(hexcolor, l);

        console.log(`Tint: ${tint}`);
      
        return tint;
      }
      



    function hexToHsl(H) {
        // Convert hex to RGB first
        let r = 0, g = 0, b = 0;
        if (H.length === 4) {
            r = "0x" + H[1] + H[1];
            g = "0x" + H[2] + H[2];
            b = "0x" + H[3] + H[3];
        } else if (H.length === 7) {
            r = "0x" + H[1] + H[2];
            g = "0x" + H[3] + H[4];
            b = "0x" + H[5] + H[6];
        }
        // Then to HSL
        r /= 255;
        g /= 255;
        b /= 255;
        let cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;

        if (delta === 0)
            h = 0;
        else if (cmax === r)
            h = ((g - b) / delta) % 6;
        else if (cmax === g)
            h = (b - r) / delta + 2;
        else
            h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0)
            h += 360;

        l = (cmax + cmin) / 2;
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        return "hsl(" + h + "," + s + "%," + l + "%)";
    }
    function hslToHex(hsl) {
        console.log(`Converting HSL to hex: ${hsl}`);
        const hslArr = hsl.slice(4, -1).split(',').map((value, index) => {

                return parseFloat(value);
            
        });

        let [h, s, l] = hslArr;

        console.log(`HSL: ${h}, ${s}, ${l}`);


        let r = 0;
        let g = 0;
        let b = 0;

        h /= 360;
        s /= 100;
        l /= 100;
        if (s === 0) {
            r = g = b = l; // achromatic
        } else {
            const hue2rgb = (p, q, t) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            };
            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }
        const toHex = x => {
            const hex = Math.round(x * 255).toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        };

        console.log(`RGB: ${r}, ${g}, ${b}`);
        console.log(`HEX: #${toHex(r)}${toHex(g)}${toHex(b)}`);

        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }



    json.brand.name = hyphenateAndLowercase(json.brand.displayName);

    Object.keys(json.color.theme).forEach(key => {
        const color = json.color.theme[key];
        const main = color.main;
        const contrast = getContrast(main);
        const subtleHover = getSubtleHover(contrast);
        const neutral = getNeutral(contrast);
        const transparent = 'rgba(255,255,255,0)';
        const tint = getTint(main);
        const subtleHoverContrast = getSubtleHoverContrast(contrast);
        const tintContrast = getTintContrast(main, contrast);

        color.contrast = contrast;
        color.subtleHover = subtleHover;
        color.neutral = neutral;
        color.transparent = transparent;
        color.tint = tint;
        color.subtleHoverContrast = subtleHoverContrast;
        color.tintContrast = tintContrast;
    });

    json.type.fonts = [
        {
          "name": "default",
          "displayName": "Default font",
          "fontName": fonts[json.type.wizard.body].name,
          "url": fonts[json.type.wizard.body].url
        },
        {
          "name": "display",
          "displayName": "Display font",
          "fontName": fonts[json.type.wizard.display].name,
          "url": fonts[json.type.wizard.display].url
        }
      ]

      json.type.typefaces.body = fonts[json.type.wizard.body].name;
      json.type.typefaces.display = fonts[json.type.wizard.display].name;

    return json;
}
