import { useState } from "react";
import Button from "./foundry-react/Button";
import Input from "./foundry-react/Input";
import { adaptJson } from "./adaptJson";
import { createBin, readBin, updateBin } from "../util/jsonbin";
import { fonts } from "../util/fonts";
import { showOverlaySpinner } from "./loading";

const INDEX_BIN_ID = "6432bfa4ace6f33a2207b00b";


const WizardForm = ({ pages, initialFormData, onUpdateFormData }) => {
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [formData, setFormData] = useState(initialFormData);

    function contrastIsHighEnough(hex, threshold = 128) {
        const rgb = parseInt(hex, 16);
        const red = (rgb >> 16) & 0xff;
        const green = (rgb >> 8) & 0xff;
        const blue = (rgb >> 0) & 0xff;
        const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
      
        return brightness < threshold;
      }
      
      function getRandomHexColorWithHighContrastToWhite() {
        let color;
        do {
          color = Math.floor(Math.random() * 0xffffff).toString(16);
        } while (!contrastIsHighEnough(color) || color.length < 6);
      
        return '#' + color;
      }
      
      

    const handlePrevious = () => {
        setCurrentPageIndex((prevIndex) => prevIndex - 1);
    };

    const handleNext = () => {
        setCurrentPageIndex((prevIndex) => prevIndex + 1);
        adaptJson(formData);
    };

    const getTextColor = (color) => {
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#ffffff';
    };


    const handleFormChange = (event) => {
        const { name, value } = event.target;
        const jsonPath = name.replace(/\[(\w+)\]/g, ".$1").replace(/^\./, "");
        const keys = jsonPath.split(".");
        const updatedFormData = { ...formData };

        let updatedValue = value;
        if (/^{.*}$/.test(value)) {
            updatedValue = JSON.parse(value);
        }

        let nestedObj = updatedFormData;
        keys.forEach((key, index) => {
            if (index === keys.length - 1) {
                nestedObj[key] = updatedValue;
            } else {
                if (!nestedObj.hasOwnProperty(key) || typeof nestedObj[key] !== "object") {
                    nestedObj[key] = {};
                }
                nestedObj = nestedObj[key];
            }
        });

        setFormData(updatedFormData);

        onUpdateFormData(adaptJson(formData));
    };

    const handleFormSubmit = async () => {
        // show spinner
        showOverlaySpinner();

        // Fetch index
        const index = await readBin(INDEX_BIN_ID);

        // Find an unused ID
        function getRandomNullId(index) {
            const nullKeys = Object.keys(index).filter((key) => index[key] === null);
            if (nullKeys.length === 0) {
              return null;
            }
          
            const randomIndex = Math.floor(Math.random() * nullKeys.length);
            return nullKeys[randomIndex];
          }
          
          let recordId = getRandomNullId(index);
          

        // If no unused IDs, inform the user
        if (recordId === null) {
            alert("No unused IDs available.");
            return;
        }

        // Create a bin with the form data
        const binData = JSON.stringify(formData, null, 2);
        const { metadata: { id: binId } } = await createBin(binData, formData.brand.name);

        // Update the index with the new bin ID
        index[recordId] = binId;
        const updatedIndex = JSON.stringify(index, null, 2);
        await updateBin(INDEX_BIN_ID, updatedIndex);

        // Inform the user that the data has been submitted
        // redirect to docs for this brand at http://docs.foundry.style/${recordId}
        if (window.location.href.includes("foundry.style")) {
  window.location.href = `https://docs.foundry.style/${recordId}`;
} else if (window.location.href.includes("localhost")) {
  window.location.href = `http://localhost:3000/${recordId}`;
} else if (window.location.href.includes("netlify.app")) {
  window.location.href = `https://foundry-docs.netlify.app/${recordId}`;
} else {
  window.location.href = `https://docs.foundry.style/${recordId}`;
}

    };


    

    const currentPage = pages[currentPageIndex];

    return (
        <div className="lg:p-8 w-full">
            <div className="🔥 bg-neutral-50 p-4 lg:p-10 lg:w-4/5 mx-auto">
                <h2 className="🔥 h2 mb-10">{currentPage.title}</h2>
                {currentPage.questions.map((question) => {
                    const value = question.id.split(".").reduce((obj, key) => obj?.[key], formData);
                    const inputProps = {
                        className: "block w-full lg:w-96",
                        type: "text",
                        name: question.id,
                        value: value,
                        onChange: handleFormChange,
                        size: "large",
                        variant: "form-highlight"
                    };
                    if (question.type === "checkbox") {
                        inputProps.type = "checkbox";
                        inputProps.options = question.options;
                    } else if (question.type === "radio") {
                        inputProps.type = "radio";
                        inputProps.options = question.options;
                    } else if (question.type === "color") {
                        inputProps.type = "text";
                    } else if (question.type === "font") {
                        inputProps.type = "select";
                    }
                    return (
                        // if question.type === "color" then make the div inline
                        <div className={`mb-4 ${question.type === "color" ? "inline-flex mr-8" : ""}`} key={question.id}>

                            {question.type === "text" && (
                                <><label className="block font-semibold mb-2">{question.text}</label><Input size="large" {...inputProps} /></>
                            )}
                            {question.type === 'color' && (
                                <div className="flex inline-flex flex-col shadow-md bg-white">

                                    <input {...inputProps} style={{
                                        color: getTextColor(value),
                                        backgroundColor: value,
                                    }}
                                        className="p-4 h-40 flex flex-col text-sm font-semibold gap-2" />
                                    <label htmlFor={question.id} className="flex justify-between bg-white text-lg p-4 w-64 font-semibold">
                                        {question.text}
                                        <button className="🔥 🧩:btn 📐:small 🎨:neutral-dark 👀:block ✋" onClick={
                                        // get a random color and set it as the value
                                        () => {
                                            const randomColor = getRandomHexColorWithHighContrastToWhite();
                                            // set the value of the input to the random color
                                            const input = document.querySelector(`input[name="${question.id}"]`);
                                            input.value = randomColor;
                                            handleFormChange({ target: input });
                                        }
                                    }>
                                        Randomise
                                    </button>
                                    </label>

                                </div>

                            )}
                            {question.type === "checkbox" && (
                                <div>
                                    <label className="block font-semibold mb-2">{question.text}</label>
                                    {question.options.map((option) => (
                                        <div key={option}>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="form-checkbox h-5 w-5 text-blue-600"
                                                    name={question.id}
                                                    value={option}
                                                    onChange={handleFormChange}
                                                    checked={value.includes(option)}
                                                />
                                                <span className="ml-2">{option}</span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {question.type === "radio" && (
                                <div>
                                    <label className="block font-semibold mb-2">{question.text}</label>
                                    {question.options.map((option) => (
                                        <div key={option.value}>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    className="form-radio h-5 w-5 text-blue-600"
                                                    name={question.id}
                                                    value={option.value}
                                                    onChange={handleFormChange}
                                                    checked={value === option.value}
                                                />
                                                <span className="ml-2">{option.label}</span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {question.type === "font" && (
                                <div className="🔥 bg-neutral-100 flex flex-col lg:flex-row gap-10 mb-8 p-4 rounded-md">
                                    <div className="lg:w-1/3">
                                        <label className="block font-semibold mb-2">{question.text}</label>
                                        {/* helper text */}
                                        <p className="text-sm text-neutral-500 mb-2">
                                            {question.helper}
                                        </p>
                                        <select
                                            className="block w-full lg:w-80 🔥 🧩:select 🎨:color1 👀:form-default ✋"
                                            name={question.id}
                                            value={value}
                                            data-thing={`${value} g`}
                                            onChange={handleFormChange}
                                        >
                                            {Object.entries(fonts).map(([key, font]) => (
                                                <option key={key} value={key}>
                                                    {font.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* font preview */}
                                    <div
                                        className={`lg:w-2/3 bg-white flex justify-center items-center rounded-md p-8`}

                                    >
                                        <div className=" ">
                                            {question.previewType === "body" ? (
                                                <p style={{ fontFamily: fonts[value].cssName }}>
                                                    The quick brown fox jumps over the lazy dog. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod.
                                                </p>
                                            ) : (
                                                <h1 style={{ fontFamily: fonts[value].cssName }}>
                                                    The quick brown fox jumps over the lazy dog.


                                                </h1>
                                            )}        </div>


                                    </div>
                                </div>
                            )}


                        </div>
                    );
                })}
                <div className="flex justify-start gap-8 pt-10">
                    {currentPageIndex > 0 && (
                        <Button color="neutral-dark" onClick={handlePrevious}>Previous</Button>
                    )}
                    {currentPageIndex < pages.length - 1 && (
                        <Button onClick={handleNext}>Next</Button>
                    )}
                    {currentPageIndex === pages.length - 1 && (
                        <Button onClick={handleFormSubmit}>Create</Button>
                    )}
                </div>
            </div>
            {Object.entries(fonts).map(([key, font]) => (
                <link key={key} href={font.url} rel="stylesheet" />
            ))}
        </div>

    );

};

export default WizardForm;
