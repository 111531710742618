export const fonts = {
    "Roboto": {
        "name": "Roboto",
        "url": "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap",
        "cssName": "'Roboto', sans-serif"
    },
    "OpenSans": {
        "name": "Open Sans",
        "url": "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap",
        "cssName": "'Open Sans', sans-serif"
    },
    "Lato": {
        "name": "Lato",
        "url": "https://fonts.googleapis.com/css2?family=Lato:wght@400;600;700;900&display=swap",
        "cssName": "'Lato', sans-serif"
    },
    "Montserrat": {
        "name": "Montserrat",
        "url": "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&display=swap",
        "cssName": "'Montserrat', sans-serif"
    },
    "Raleway": {
        "name": "Raleway",
        "url": "https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap",
        "cssName": "'Raleway', sans-serif"
    },
    "SourceSansPro": {
        "name": "Source Sans Pro",
        "url": "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap",
        "cssName": "'Source Sans Pro', sans-serif"
    },
    "NotoSans": {
        "name": "Noto Sans",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap",
        "cssName": "'Noto Sans', sans-serif"
    },
    "Oswald": {
        "name": "Oswald",
        "url": "https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap",
        "cssName": "'Oswald', sans-serif"
    },
    "PTSans": {
        "name": "PT Sans",
        "url": "https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap",
        "cssName": "'PT Sans', sans-serif"
    },
    "Muli": {
        "name": "Muli",
        "url": "https://fonts.googleapis.com/css2?family=Muli:wght@400;600;700;800&display=swap",
        "cssName": "'Muli', sans-serif"
    },
    "Ubuntu": {
        "name": "Ubuntu",
        "url": "https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap",
        "cssName": "'Ubuntu', sans-serif"
    },
    "Nunito": {
        "name": "Nunito",
        "url": "https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap",
        "cssName": "'Nunito', sans-serif"
    },
    "Poppins": {
        "name": "Poppins",
        "url": "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap",
        "cssName": "'Poppins', sans-serif"
    },
    "PlayfairDisplay": {
        "name": "Playfair Display",
        "url": "https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700;900&display=swap",
        "cssName": "'Playfair Display', serif"
    },
    "FiraSans": {
        "name": "Fira Sans",
        "url": "https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700;800&display=swap",
        "cssName": "'Fira Sans', sans-serif"
    },
    "Lora": {
        "name": "Lora",
        "url": "https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap",
        "cssName": "'Lora', serif"
    },
    "JosefinSans": {
        "name": "Josefin Sans",
        "url": "https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600;700&display=swap",
        "cssName": "'Josefin Sans', sans-serif"
    },
    "LibreBaskerville": {
        "name": "Libre Baskerville",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap",
        "cssName": "'Libre Baskerville', serif"
    },
    "CrimsonText": {
        "name": "Crimson Text",
        "url": "https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600;700&display=swap",
        "cssName": "'Crimson Text', serif"
    },
    "Arvo": {
        "name": "Arvo",
        "url": "https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap",
        "cssName": "'Arvo', serif"
    },
    "Domine": {
        "name": "Domine",
        "url": "https://fonts.googleapis.com/css2?family=Domine:wght@400;700&display=swap",
        "cssName": "'Domine', serif"
    },
    "OldStandardTT": {
        "name": "Old Standard TT",
        "url": "https://fonts.googleapis.com/css2?family=Old+Standard+TT:wght@400;700&display=swap",
        "cssName": "'Old Standard TT', serif"
    },
    "WorkSans": {
        "name": "Work Sans",
        "url": "https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700;800&display=swap",
        "cssName": "'Work Sans', sans-serif"
    },
    "Karla": {
        "name": "Karla",
        "url": "https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap",
        "cssName": "'Karla', sans-serif"
    },
    "QuickSand": {
        "name": "Quick Sand",
        "url": "https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap",
        "cssName": "'Quicksand', sans-serif"
    },
    "Cabin": {
        "name": "Cabin",
        "url": "https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap",
        "cssName": "'Cabin', sans-serif"
    },
    "Barlow": {
        "name": "Barlow",
        "url": "https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700;800&display=swap",
        "cssName": "'Barlow', sans-serif"
    },
    "Hind": {
        "name": "Hind",
        "url": "https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600;700&display=swap",
        "cssName": "'Hind', sans-serif"
    },
    "Inconsolata": {
        "name": "Inconsolata",
        "url": "https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap",
        "cssName": "'Inconsolata', monospace"
    },
    "Rubik": {
        "name": "Rubik",
        "url": "https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;900&display=swap",
        "cssName": "'Rubik', sans-serif"
    },
    "Heebo": {
        "name": "Heebo",
        "url": "https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700;800;900&display=swap",
        "cssName": "'Heebo', sans-serif"
    },
    "Bangers": {
        "name": "Bangers",
        "url": "https://fonts.googleapis.com/css2?family=Bangers&display=swap",
        "cssName": "'Bangers', cursive"
    },
    "SpaceMono": {
        "name": "Space Mono",
        "url": "https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap",
        "cssName": "'Space Mono', monospace"
    },
    "AmaticSC": {
        "name": "Amatic SC",
        "url": "https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap",
        "cssName": "'Amatic SC', cursive"
    },
    "TitilliumWeb": {
        "name": "Titillium Web",
        "url": "https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700;900&display=swap",
        "cssName": "'Titillium Web', sans-serif"
    }
}