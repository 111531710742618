import React, { useState, useEffect } from 'react';

const Tab = ({ label, activeTab, onClick }) => (
  <button
    className={`mr-4 text-sm font-semibold py-2 px-4 rounded-t-lg ${activeTab === label ? 'bg-white' : '🔥 hover:bg-neutral-100'}`}
    onClick={() => onClick(label)}
    type="button" // Line 28: Added 'type' attribute to the button element
  >
    {label}
  </button>
);

const FileTabs = ({ brandID, binID }) => {
  const [activeTab, setActiveTab] = useState('config.json');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [refreshed, setRefreshed] = useState(null);

  const filenames = [
    'config.json',
    'tokens.json',
    'styles.css',
    'styles-flat.css',
    'tokens.css',
  ];

  useEffect(() => {
    fetchContent(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]); // Line 32: Added eslint-disable-next-line comment

  useEffect(() => {
    fetchContent(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandID]); // Line 36: Added eslint-disable-next-line comment

  const fetchContent = async (filename) => {
    setLoading(true);
    const response = await fetch(`https://cdn.foundry.style/${brandID}/${filename}`);
    const data = await response.text();
    setContent(data);
    setLoading(false);
  };

  const refresh = async () => { // Line 43: Removed unused 'filename' argument
    setLoading(true);
    const response = await fetch(`https://cdn.foundry.style/g/${brandID}/`);
    setLoading(true);
    setRefreshed(response.status === 200 ? '👍' : '👎');
    setTimeout(() => {
      setRefreshed(null);
    }, 2000);
    fetchContent(activeTab);
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto">
        <h2 className='🔥 h2'>
            {brandID}
            <a className="reset" href={`https://docs.foundry.style/${brandID}`}>
                <i className={`fa fa-external-link pl-2 🔥 body`} aria-hidden="true"></i>
            </a>
        </h2>
        <p className='🔥'><code className='text-sm'>{binID}</code></p>
        <div className="flex justify-start mt-4 pl-2">
          {filenames.map((filename) => (
            <Tab
              key={filename}
              label={filename}
              activeTab={activeTab}
              onClick={(label) => setActiveTab(label)}
            />
          ))}
          <button className="reset 🔥 hover:text-primary-main" onClick={refresh} type="button">
            <i className={`fa fa-refresh ${loading ? 'animate-spin' : ''} ${refreshed ? 'hidden' : ''}`} aria-hidden="true"></i> 
            {refreshed}
          </button>
        </div>
        <div className="bg-white shadow-md rounded-lg">
          <div className="p-4">
            {loading ? (
              <p>Loading from CDN...</p>
            ) : (
              <pre className="overflow-auto">
                <code>{content}</code>
              </pre>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileTabs;
